
import { useContext, useEffect, useState } from "react";
import { MyContext } from "../../../App";
import sorry from "../../../assets/images/sorry.png"
import { Link } from "react-router-dom";

const Updating  = ()=>{
    const context = useContext(MyContext);
    useEffect(() => {
        context.setisHeaderFooterShow(false);
      }, []);
  return(
    <>
   
      <div className="container main-container-avail">
        <div className="row">
            <h1 className="mt-2 ml-2">  Apologies... </h1>
        </div>
        <div className="row main-row-avail">
            <div className="col-md-8  ">
            <div class="card-contenter mt-5">
                <h1 className="fonter">But Wonzilla Is Currently Available Only  <span class="highlighter">In Pakistan.</span></h1>
                <p> We are actively working on it, and InshaAllah, Wonzilla will be available internationally very soon.</p>
            <Link to="/">  
              <button class="explore-btner btnspecialborders">
            {/* <div class="center"> */}
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      Explore Products In Pakistan
        {/* </div> */}
</button>
 </Link>
            </div>

            </div>

            <div className="col-md-4 col-sm-12">
            <div class="card-image">
            
                <img src={sorry} alt="Tutor Reactive Logo" id="logo-placeholder"/>
            </div>
            </div>
        </div>
      </div>
    
    </>
  )

}


export default Updating;