import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { IoBagCheckOutline } from "react-icons/io5";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { MyContext } from "../../App";
import { fetchDataFromApi, postData, deleteData } from "../../utils/api";

import { Link, useNavigate } from "react-router-dom";
const Checkout = () => {
  const [province, setprovince] = useState('');

  const handleChange = (event) => {
    setprovince(event.target.value);
  };


  const [formFields, setFormFields] = useState({
    fullName: "",
    country: "",
    streetAddressLine1: "",
    streetAddressLine2: "",
    city: "",
    state: "",
    zipCode: "",
    phoneNumber: "",
    email: "",
  });

  const [cartData, setCartData] = useState([]);
  const [totalAmount, setTotalAmount] = useState();


  useEffect(() => {
    const fetchData = async () => {
    const user = JSON.parse(localStorage.getItem("user"));

    await  fetchDataFromApi(`/api/cart?userId=${user?.userId}`).then((res) => {
        setCartData(res);
  
        setTotalAmount(
          res.length !== 0 &&
          res
            .map((item) => parseInt(item.price) * item.quantity)
            .reduce((total, value) => total + value, 0
            )
        );
      });
    };

    fetchData();
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
    const user = JSON.parse(localStorage.getItem("user"));
    fetchDataFromApi(`/api/cart?userId=${user?.userId}`).then((res) => {
      setCartData(res);

      setTotalAmount(
        res.length !== 0 &&
        res
          .map((item) => parseInt(item.price) * item.quantity)
          .reduce((total, value) => total + value, 0
          )
      );
    });
  }, []);

  const onChangeInput = (e) => {
    setFormFields(() => ({
      ...formFields,
      [e.target.name]: e.target.value,
    }));
  };

  const context = useContext(MyContext);
  const history = useNavigate();



const checkout = (e) => {


  e.preventDefault();

  const user = JSON.parse(localStorage.getItem("user"));
  const checkoutProduct = JSON.parse(localStorage.getItem("checkoutProduct"));

  // If no cart data is available, use checkoutProduct
  const cartDataToUse = cartData?.length ? cartData : [checkoutProduct]; // Check cartData or fallback to checkoutProduct

  if (!cartDataToUse.length) {
    context.setAlertBox({
      open: true,
      error: true,
      msg: "No product in cart",
    });
    return;
  }

  // Validation checks
  if (formFields.fullName === "") {
    context.setAlertBox({
      open: true,
      error: true,
      msg: "Please fill full name ",
    });
    return false;
  }

  if (formFields.country === "") {
    context.setAlertBox({
      open: true,
      error: true,
      msg: "Please fill country ",
    });
    return false;
  }

  if (formFields.streetAddressLine1 === "") {
    context.setAlertBox({
      open: true,
      error: true,
      msg: "Please fill Street address",
    });
    return false;
  }

  if (formFields.city === "") {
    context.setAlertBox({
      open: true,
      error: true,
      msg: "Please fill city ",
    });
    return false;
  }

  if (formFields.zipCode === "") {
    context.setAlertBox({
      open: true,
      error: true,
      msg: "Please fill zipCode ",
    });
    return false;
  }

  if (formFields.phoneNumber === "") {
    context.setAlertBox({
      open: true,
      error: true,
      msg: "Please fill phone Number ",
    });
    return false;
  }
  if (formFields.state === "") {
    context.setAlertBox({
      open: true,
      error: true,
      msg: "Please fill State ",
    });
    return false;
  }

  if (formFields.email === "") {
    context.setAlertBox({
      open: true,
      error: true,
      msg: "Please fill email",
    });
    return false;
  }

  // Further validation checks...
  
  // Order details
  const addressInfo = {
    name: formFields.fullName,
    phoneNumber: formFields.phoneNumber,
    address: formFields.streetAddressLine1 + formFields.streetAddressLine2,
    pincode: formFields.zipCode,
    date: new Date().toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    }),
  };

  const payLoad = {
    name: addressInfo.name,
    phoneNumber: formFields.phoneNumber,
    address: addressInfo.address,
    pincode: addressInfo.pincode,
    amount: parseInt(totalAmount), // Ensure this is correct
    email: user.email,
    userid: user.userId,
    products: cartData,
    date: addressInfo.date,
  };

  console.log(payLoad);
  console.log(cartData);


  // Post order
  postData(`/api/orders/create`, payLoad).then((res) => {
    fetchDataFromApi(`/api/cart?userId=${user?.userId}`).then((res) => {
      res?.length !== 0 &&
        res?.map((item) => {
          deleteData(`/api/cart/${item?.id}`).then((res) => {});
        });
      setTimeout(() => {
        context.getCartData();
      }, 1000);
      history("/orders");
    });
  });

 
};


  return (
    <section className="section">
      <div className="container">
        <form className="checkoutForm" onSubmit={checkout}>
          <div className="row">
            <div className="col-md-8">
              <h2 className="hd">BILLING DETAILS</h2>

              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="form-group">
                    <TextField
                      label="Full Name *"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="fullName"
                      onChange={onChangeInput}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <TextField
                      label="Country *"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="country"

                      onChange={onChangeInput}
                    />
                  </div>
                </div>
              </div>

              <h6>Street address *</h6>

              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <TextField
                      label="House number and street name"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="streetAddressLine1"
                      onChange={onChangeInput}
                    />
                  </div>

                  <div className="form-group">
                    <TextField
                      label="Apartment, suite, unit, etc. (optional)"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="streetAddressLine2"
                      onChange={onChangeInput}
                    />
                  </div>
                </div>
              </div>

              <h6>Town / City *</h6>

              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <TextField
                      label="City"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="city"
                      onChange={onChangeInput}
                    />
                  </div>
                </div>
              </div>

              <h6>Provinces</h6>

              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">

                    <TextField
                      label="State"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="state"
                      onChange={onChangeInput}
                    />

                    {/* <FormControl className="w-100" >
        <InputLabel id="demo-simple-select-helper-label">State</InputLabel>
        <Select
           labelId="demo-simple-select-helper-label"
           id="demo-simple-select-helper"
           label="State"
           variant="outlined"
           className="w-100 white"
           name="state"
           onChange={onChangeInput}
        >
          
          <MenuItem value={10}>Punjab</MenuItem>
                    <MenuItem value={20}>Sindh</MenuItem>
                    <MenuItem value={30}>Balochistan</MenuItem>
                    <MenuItem value={40}>Gilgit Baltistan</MenuItem>
                    <MenuItem value={50}>Azad Kashmir</MenuItem>
        </Select>
      </FormControl> */}
                  </div>
                </div>
              </div>

              <h6>Postcode / ZIP *</h6>

              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <TextField
                      label="ZIP Code"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="zipCode"
                      onChange={onChangeInput}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <TextField
                      label="Phone Number"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="phoneNumber"
                      onChange={onChangeInput}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <TextField
                      label="Email Address"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="email"
                      onChange={onChangeInput}
                    />
                  </div>
                </div>
              </div>
            </div>



            <div className="col-md-4">
              <div className="card border p-3 cartDetails">
                <h4>CART TOTALS</h4>

                <div className="d-flex align-items-center mb-3">
                  <span>Subtotal</span>
                  <span className="ml-auto text-red font-weight-bold">
                  {
        (cartData?.length !== 0 
            ? cartData.map(item => parseInt(item.price) * item.quantity)
                .reduce((total, value) => total + value, 0) 
            : 0
        )?.toLocaleString('en-US', { style: 'currency', currency: 'PKR' })
    }
                  </span>
                </div>

                <div className="d-flex align-items-center mb-3">
                  <span>Shipping</span>
                  <span className="ml-auto"><b> RS 199 </b></span>
                </div>

                <div className="d-flex align-items-center mb-3">
                  <span>Estimate for</span>
                  <span className="ml-auto"><b>KARACHI</b></span>
                </div>

                <div className="d-flex align-items-center">
                  <span>Total</span>
                  <span className="ml-auto text-red font-weight-bold">
                  {
        (cartData?.length !== 0 
            ? cartData.map(item => parseInt(item.price) * item.quantity)
                .reduce((total, value) => total + 199 + value, 0) 
            : 199
        )?.toLocaleString('en-US', { style: 'currency', currency: 'PKR' })
    }
                  </span>
                </div>


                <br />
                <Button
                  type="submit"
                  className="btn-blue bg-red btn-lg btn-big"
                >
                  <IoBagCheckOutline /> &nbsp; Select Payment Method
                </Button>

              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Checkout;
